import React, {MouseEventHandler, ReactNode, useEffect} from 'react';
import Image from 'next/image';
import closeIcon from 'assets/close.svg';

interface Props {
  name?: string,
  isVisible?: boolean,
  onClose: MouseEventHandler,
  rounded?: boolean,
  positionClass?: string,
  bgClass?: string,
  roundedClass?: string,
  children: ReactNode,
  paddingClass?: string,
  className?: string,
  alignRight?: boolean,
  closeWrapper?: string,
  modalImpressionEvent?: () => void,
}

function Modal(props: Props) {
  const {
    isVisible,
    onClose,
    bgClass = 'bg-white',
    roundedClass = 'rounded-[10px]',
    paddingClass = 'p-[30px]',
    alignRight = false,
    closeWrapper = '',
    modalImpressionEvent,
  } = props;
  if (!isVisible) {
    return null;
  }

  useEffect(() => {
    if (isVisible) {
      modalImpressionEvent?.();
    }
  }, [isVisible]);

  return (
    <>
      <div className={`bg-[rgba(0,0,0,0.25)] h-full w-screen fixed left-0 top-0 z-[500]`}
           onClick={onClose}>
      </div>
      <div className={`${bgClass} fixed ${alignRight ? 'top-0 right-0 h-full' : 'top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] max-h-[80vh] overflow-y-scroll no-scrollbar'} w-fit ${paddingClass} ${!alignRight ? roundedClass : ''} z-[500] shadow-[0_0_30px_0px_rgba(0,0,0,0.1)] xsScreen:w-full`}
           onClick={e => e.stopPropagation()}>
        <div
          className={`absolute top-[5px] right-[5px] z-[500] cursor-pointer ${closeWrapper}`}
          id='modal-close-button'
          onClick={onClose}
        >
          <Image
            src={closeIcon}
            alt={'close'}
          />
        </div>
        {props.children}
      </div>
    </>
  );
}

export default Modal;
