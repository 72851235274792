import React from 'react';
import Image from 'next/image';
import Modal from 'components/Modal/_default/Modal';
import phoneCallLogo from 'assets/phone-call.svg';
import mailLogo from 'assets/mail.svg';
import raiseIssueIcon from 'assets/raise-issue.svg';
import extrnalLinkIcon from 'assets/external-link-dark.svg';
interface ContactSupportProps {
    isVisible: boolean; 
    toggle: (value: boolean) => void;
}

const ContactSupport: React.FC<ContactSupportProps> = ({ isVisible, toggle }) => {
    return (
        <>
            {/* Contact Support Modal */}
            <Modal
                isVisible={isVisible}
                onClose={() => toggle(!isVisible)}
                alignRight
            >
                <section className='w-[420px] flex flex-col gap-[40px] justify-between'>
                    <p className={'text-theme_1_black font-bold text-[24px]'}>Get Support</p>
                    <div className='flex flex-col gap-[25px] justify-between font-normal'>
                        <div className='flex gap-[13px] items-center'>
                            <Image
                                src={phoneCallLogo}
                                alt='mail-logo'
                            />
                            <div className='flex flex-col gap-[5px] grow'>
                                <p className='text-[14px] leading-[20px]'>Call Us</p>
                                <p className='flex justify-between'>
                                    <span className='text-[20px] leading-[16px]'>
                                        <a
                                            href="tel:022-48964047"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            0755-4304201
                                        </a>
                                    </span>
                                    <span className='text-[14px] leading-[20px] text-theme_1_grey2'>Mon - Sat, 10am - 7pm</span>
                                </p>
                            </div>
                        </div>
                        <hr className='border-theme_1_border' />
                        <div className='flex gap-[13px] items-center'>
                            <Image
                                src={mailLogo}
                                alt='mail-logo'
                            />
                            <div className='flex flex-col gap-[5px]'>
                                <p className='text-[14px] leading-[20px]'>Email</p>
                                <a
                                    href="mailto:support@roposoclout.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='text-[20px] leading-[16px]'
                                >
                                    support@roposoclout.com
                                </a>
                            </div>
                        </div>
                        <hr className='border-theme_1_border' />
                        <div className='flex gap-[13px] items-center'>
                            <Image
                                src={raiseIssueIcon}
                                alt='Issue Icon'
                            />
                            <a
                              href={process.env.NEXT_PUBLIC_FRESHDESK_URL}
                              target="_blank"
                              rel="noopener noreferrer"
                              className='text-[20px] leading-[16px] flex items-center justify-between w-full'
                            >
                                    <span>
                                      Raise Issue
                                    </span>
                              <Image
                                src={extrnalLinkIcon}
                                alt='Link Icon'
                              />
                            </a>
                        </div>
                    </div>
                </section>
          </Modal>
        </>
    );
};

export default ContactSupport;
